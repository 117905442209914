@font-face {
	font-family: 'hindiunicode';
	src: local('hindiunicode'), local('hindiunicode-Regular'), url(../fonts/surekhnormal.woff2) format('woff2');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'hindiunicode';
	src: local('hindiunicode'), local('hindiunicode-Bold'), url(../fonts/surekhbold.woff2) format('woff2');
	font-weight: 500 700;
	font-style: normal;
}

/* roboto-400normal - latin */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-display: swap;
	font-weight: 400;
	src: local('Roboto Regular '), local('Roboto-Regular'),
		url('../../node_modules/typeface-roboto/files/roboto-latin-400.woff2') format('woff2');
}

/* roboto-500normal - latin */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-display: swap;
	font-weight: 500;
	src: local('Roboto Medium '), local('Roboto-Medium'),
		url('../../node_modules/typeface-roboto/files/roboto-latin-500.woff2') format('woff2');
}

/* roboto-700normal - latin */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-display: swap;
	font-weight: 700;
	src: local('Roboto Bold '), local('Roboto-Bold'),
		url('../../node_modules/typeface-roboto/files/roboto-latin-700.woff2') format('woff2');
}
