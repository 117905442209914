html,
body {
	margin: 0;
	font-family: 'Roboto', sans-serif;
	letter-spacing: 0.05em;
	word-spacing: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	position: relative;
	height: 100vh;
	font-size: 10px;
}

.locale-hi {
	font-family: 'hindiunicode', helvetica !important;
	letter-spacing: 0 !important;
	word-spacing: 0.15em !important;
}

p.locale-hi {
	margin: 0 0 0.85em;
	text-indent: 3em;
}

.locale-en {
	font-family: 'Roboto';
	letter-spacing: 0.05em;
	word-spacing: 0;
}

@keyframes sphamadjust-hue {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

hide {
	visibility: hidden;
}
